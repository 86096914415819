import { Box, Text, Image, Heading, Card } from 'rebass';
import Backgroundfm from "../../../../src/pages/cato.jpg";
import * as React from 'react';
export default {
  Box,
  Text,
  Image,
  Heading,
  Card,
  Backgroundfm,
  React
};